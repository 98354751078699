<template>
    <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-primary mb-3">
        <div class="container-fluid">
            <router-link :to="{name: 'Home'}" class="navbar-brand">A Smile :)</router-link>
            <template v-if="$store.state.auth && $store.state.auth.is_signed_in">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown">
                            <router-link :to="{name: 'Project'}" class="nav-link"><i class="bi bi-people-fill"></i> 案件</router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <router-link :to="{name: 'Customer'}" class="nav-link"><i class="bi bi-people-fill"></i> 一覧</router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-bar-chart-fill"></i> レポート
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><router-link :to="{name: 'ReportForecast'}" class="dropdown-item">着地予想</router-link></li>
                                <li><router-link :to="{name: 'ReportFollowUp'}" class="dropdown-item">追客</router-link></li>
                                <li><router-link :to="{name: 'ReportSaleReport'}" class="dropdown-item">月別優先度別営業報告件数</router-link></li>
                                <li><router-link :to="{name: 'ReportAppointment'}" class="dropdown-item">アポまでの日数</router-link></li>
                                <li><router-link :to="{name: 'ReportOrder'}" class="dropdown-item">受注済み一覧</router-link></li>
                                <li><router-link :to="{name: 'ReportResponse'}" class="dropdown-item">反響実績</router-link></li>
                                <li><router-link :to="{name: 'ReportStatus'}" class="dropdown-item">ステータス別案件数</router-link></li>
                                <li><router-link :to="{name: 'ReportWeeklyActivity'}" class="dropdown-item">週次活動実績</router-link></li>
                                <li><router-link :to="{name: 'ReportCall'}" class="dropdown-item">日時架電実績</router-link></li>
                                <li><router-link :to="{name: 'ReportRate'}" class="dropdown-item">アポ率・受注率</router-link></li>
                                <li><router-link :to="{name: 'PageCompetitorReport'}" class="dropdown-item">競合</router-link></li>
                                <li><router-link :to="{name: 'ReportAppointmentRate'}" class="dropdown-item">アポ転換率</router-link></li>
                                <li><router-link :to="{name: 'ReportAppointmentRateTransition'}" class="dropdown-item">アポ転換率推移</router-link></li>
                                <li><router-link :to="{name: 'ReportIndicator'}" class="dropdown-item">指標推移</router-link></li>
                                <li><router-link :to="{name: 'ReportTransition'}" class="dropdown-item">反響別推移</router-link></li>
                                <li><router-link :to="{name: 'ReportVoice'}" class="dropdown-item">お客様の声</router-link></li>
                                <li><router-link :to="{name: 'ReportContract'}" class="dropdown-item">受注率</router-link></li>
                            </ul>
                        </li>
                        <li v-if="$store.getters['auth/canEditMaster']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-gear-fill"></i> マスタ管理
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><router-link :to="{name: 'MasterDepartment'}" class="dropdown-item">事業部マスタ</router-link></li>
                                <li><router-link :to="{name: 'MasterProbability'}" class="dropdown-item">優先度マスタ</router-link></li>
                                <li><router-link :to="{name: 'MasterCertainty'}" class="dropdown-item">確実度マスタ</router-link></li>
                                <li><router-link :to="{name: 'MasterDetailedRank'}" class="dropdown-item">サブ評価マスタ</router-link></li>
                                <li><router-link :to="{name: 'MasterArea'}" class="dropdown-item">エリアマスタ</router-link></li>
                                <li><router-link :to="{name: 'MasterChannel'}" class="dropdown-item">チャネルマスタ</router-link></li>
                                <li><router-link :to="{name: 'MasterAppointmentSource'}" class="dropdown-item">アポ取得リストマスタ</router-link></li>
                                <li><router-link :to="{name: 'MasterCompetitor'}" class="dropdown-item">競合マスタ</router-link></li>
                                <li><router-link :to="{name: 'MasterDetailedChannel'}" class="dropdown-item">送信元ページマスタ</router-link></li>
                                <li><router-link :to="{name: 'MasterDocument'}" class="dropdown-item">資料マスタ</router-link></li>
                            </ul>
                        </li>
                        <li v-if="$store.getters['auth/canManageUser'] || $store.getters['auth/canResyncSpreadsheet']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-key-fill"></i> システム管理
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li v-if="$store.getters['auth/canManageUser']"><router-link :to="{name: 'SystemUser'}" class="dropdown-item">ユーザ管理</router-link></li>
                                <li v-if="$store.getters['auth/canManageUser']"><router-link :to="{name: 'SystemWebsite'}" class="dropdown-item">Webサイト管理</router-link></li>
                                <li v-if="$store.getters['auth/canResyncSpreadsheet']"><router-link :to="{name: 'SystemSpreadsheet'}" class="dropdown-item">スプレッドシート同期</router-link></li>
                            </ul>
                        </li>
                    </ul>
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-person-circle"></i> {{ user_name }} さん
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><router-link :to="{name: 'AccountInfo'}" class="dropdown-item">アカウント</router-link></li>
                                <li v-if="$store.getters['auth/canEditProject']"><router-link :to="{name: 'AccountGmail'}" class="dropdown-item">Gmail 連携</router-link></li>
                                <li><a class="dropdown-item" role="button" @click.prevent="signOut()">ログアウト</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </template>
        </div>
    </nav>

    <div class="container container-main">
        <router-view/>
    </div>

    <screen-loader v-if="loading > 0"></screen-loader>

    <message-dialog ref="message_dialog" :title="dialog.title" :message="dialog.message" :icon="dialog.icon"></message-dialog>
</template>

<script>
import ScreenLoader from '@/components/tools/ScreenLoader.vue'
import MessageDialog from '@/components/tools/MessageDialog.vue'

export default {
    name: 'App',
    components: {
        ScreenLoader,
        MessageDialog,
    },
    provide() {
        // 参考 emit/props vs provide/inject
        // https://cloudsmith.co.jp/blog/frontend/2020/12/1656030.html
        return {
            startScreenLoading: this.startScreenLoading,
            endScreenLoading: this.endScreenLoading,
            quitScreenLoading: this.quitScreenLoading,
            showMessage: this.showMessage,
            showErrorMessage: this.showErrorMessage,
            setTitle: this.setTitle,
        }
    },
    data() {
        return {
            loading: 0,

            dialog: {
                title: null,
                message: null,
                icon: null
            },
        }
    },
    mounted() {
        window.addEventListener('show_message', (event) => {
            this.showMessage(event.detail.title, event.detail.message);
        });
        window.addEventListener('show_error_message', (event) => {
            this.showErrorMessage(event.detail.title, event.detail.message);
        });
    },
    methods: {
        signOut() {
            this.$store.commit('auth/signOut') //ログイン情報削除
            this.$store.commit('condition/signOut') //検索条件削除
            this.$router.push({name: 'Signin'});
        },
        startScreenLoading() {
            this.loading++;
        },
        endScreenLoading() {
            this.loading--;
        },
        quitScreenLoading() {
            this.loading = 0;
        },
        showMessage(title, message) {
            this.dialog.title = title ?? '完了';
            this.dialog.message = message;
            this.dialog.icon = 'success';
            this.$refs.message_dialog.show();
        },
        showErrorMessage(title, message) {
            this.dialog.title = title ?? 'エラー';
            this.dialog.message = message;
            this.dialog.icon = 'error';
            this.$refs.message_dialog.show();
        },
        setTitle(title) {
            if (title) {
                document.title = title + ' | A Smile';
            } else {
                document.title = 'A Smile';
            }
        }
    },
    computed: {
        user_name() {
            return this.$store.state.auth.user_name;
        }
    },
    watch: {
        '$route'(to) {
            if (to.meta.title) {
                this.setTitle(to.meta.title);
            } else {
                this.setTitle(null);
            }
        },
    },
}
</script>

<style>
.container {
    /* background: #FFF; */
    padding-top: 1em;
    padding-bottom: 1em;
}
.modal-dialog {margin: 10% auto !important;}
</style>
